/*--------------------------------

IKO_font icon font
Generated using nucleoapp.com

-------------------------------- */

$icon-font-path: "../fonts" !default;

@font-face {
  font-family: "IKO_font";
  src: url("/assets/fonts/iko.woff2?v=12") format("woff2"), url("/assets/fonts/iko.woff?v=12") format("woff");
  font-display: swap;
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 "IKO_font";
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
$icons: (
  "aA": "ea02",
  "address-card": "ea03",
  "address-book": "ea04",
  "advanced": "ea05",
  "alarm-clock": "ea06",
  "ambulance": "ea07",
  "angle-double-down": "ea08",
  "angle-down": "ea09",
  "angle-left": "ea0a",
  "angle-right": "ea0b",
  "angle-up": "ea0c",
  "apple": "ea0d",
  "arrow-alt-circle-up": "ea0e",
  "arrow-circle-down": "ea0f",
  "arrow-circle-right-2": "ea10",
  "at": "ea11",
  "award": "ea12",
  "backspace": "ea13",
  "bags-shopping": "ea14",
  "bar": "ea15",
  "bars-2": "ea16",
  "bars": "ea17",
  "bed": "ea18",
  "beginner": "ea19",
  "bell-slash": "ea1a",
  "bell": "ea1b",
  "birthday-cake": "ea1c",
  "board": "ea1d",
  "book-open": "ea1e",
  "book": "ea1f",
  "bookmark": "ea20",
  "briefcase-medical": "ea21",
  "briefcase": "ea22",
  "burger-soda": "ea23",
  "calendar-alt": "ea24",
  "calendar-check": "ea25",
  "calendar-star": "ea26",
  "camera": "ea27",
  "caret-down": "ea28",
  "cc-amex": "ea29",
  "cc-discover": "ea2a",
  "cc-visa": "ea2b",
  "cc-mastercard": "ea2c",
  "certificate": "ea2d",
  "chalkboard-teacher": "ea2e",
  "chart-line": "ea2f",
  "check-circle": "ea30",
  "check": "ea31",
  "chevron-double-up": "ea32",
  "child": "ea33",
  "circle": "ea34",
  "city": "ea35",
  "clipboard-check-2": "ea36",
  "clipboard-check": "ea37",
  "clipboard-list-2": "ea38",
  "clipboard-list-3": "ea39",
  "clipboard-list": "ea3a",
  "clock": "ea3b",
  "clone": "ea3c",
  "cloud-download": "ea3d",
  "cloud-upload": "ea3e",
  "cog-2": "ea3f",
  "cog": "ea40",
  "coin-vertical": "ea41",
  "coin": "ea42",
  "coins": "ea43",
  "comment-lines": "ea44",
  "comment-plus": "ea45",
  "comment": "ea46",
  "comments": "ea47",
  "copy": "ea48",
  "credit-card": "ea49",
  "crown": "ea4a",
  "desktop": "ea4b",
  "donate": "ea4c",
  "edit": "ea4d",
  "ellipsis-h": "ea4e",
  "ellipsis-v-2": "ea4f",
  "ellipsis-v": "ea50",
  "envelope-2": "ea51",
  "envelope-open": "ea52",
  "envelope": "ea53",
  "exclamation-triangle": "ea54",
  "external-link": "ea55",
  "eye-slash": "ea56",
  "facebook-square": "ea57",
  "facebook": "ea58",
  "fan": "ea59",
  "favorite": "ea5a",
  "female": "ea5b",
  "file-alt": "ea5c",
  "file-pdf": "ea5d",
  "files-medical": "ea5e",
  "film-alt": "ea5f",
  "film": "ea60",
  "first-aid": "ea61",
  "fire": "ea62",
  "folder": "ea63",
  "gem": "ea64",
  "genderless": "ea65",
  "gift-2": "ea66",
  "gift": "ea67",
  "globe-americas": "ea68",
  "globe": "ea69",
  "google-play": "ea6a",
  "google-plus-square": "ea6b",
  "graduation-cap": "ea6c",
  "grip-horizontal": "ea6d",
  "handshake-alt": "ea6e",
  "harness": "ea6f",
  "heart-2": "ea70",
  "heart": "ea71",
  "home": "ea72",
  "icon_test": "ea73",
  "id-badge": "ea74",
  "id-card": "ea75",
  "image": "ea76",
  "images": "ea77",
  "info-circle": "ea78",
  "instagram": "ea79",
  "key": "ea7a",
  "kite": "ea7b",
  "kiter": "ea7c",
  "kitesignals": "ea7d",
  "language-2": "ea7e",
  "language": "ea7f",
  "laugh": "ea80",
  "lightbulb": "ea81",
  "linkedin": "ea82",
  "lock-alt-2": "ea83",
  "lock-alt": "ea84",
  "male": "ea85",
  "map-marker-alt": "ea86",
  "map": "ea87",
  "map_icon_new": "ea88",
  "map_marker": "ea89",
  "mars": "ea8a",
  "medal": "ea8b",
  "medkit": "ea8c",
  "mobile": "ea8d",
  "money-bill-wave": "ea8e",
  "mouse-pointer": "ea8f",
  "music": "ea90",
  "newspaper": "ea91",
  "paperclip": "ea92",
  "parachute-box": "ea93",
  "parking-circle": "ea94",
  "pencil": "ea95",
  "phone-2": "ea96",
  "phone-laptop": "ea97",
  "phone-plus": "ea98",
  "phone": "ea99",
  "photo-video": "ea9a",
  "plane": "ea9b",
  "play-circle": "ea9c",
  "plug": "ea9d",
  "plus-circle-2": "ea9e",
  "plus-circle": "ea9f",
  "portrait": "eaa0",
  "power-off": "eaa1",
  "powerkite": "eaa2",
  "professional": "eaa3",
  "question-circle": "eaa4",
  "quote-left": "eaa5",
  "quote-right": "eaa6",
  "redo": "eaa7",
  "restroom": "eaa8",
  "rowsigns": "eaa9",
  "rss": "eaaa",
  "search-2": "eaab",
  "search-minus": "eaac",
  "search-plus": "eaad",
  "search": "eaae",
  "send-arrow": "eaaf",
  "share-alt-2": "eab0",
  "share-alt": "eab1",
  "sheriff": "eab2",
  "shield-check-2": "eab3",
  "shield-check": "eab4",
  "shield": "eab5",
  "ship": "eab6",
  "shipping-fast": "eab7",
  "shopping-cart": "eab8",
  "shower": "eab9",
  "sign-in": "eaba",
  "sign-out": "eabb",
  "skype": "eabc",
  "sliders-h": "eabd",
  "spinner": "eabe",
  "star": "eabf",
  "store-alt": "eac0",
  "store": "eac1",
  "suitcase": "eac2",
  "sync": "eac3",
  "tag": "eac4",
  "taxi": "eac5",
  "thermometer-half": "eac6",
  "thumbs-up-2": "eac7",
  "thumbs-up": "eac8",
  "times-circle": "eac9",
  "times": "eaca",
  "tools": "eacb",
  "transgender": "eacc",
  "trash-alt": "eacd",
  "trash": "eace",
  "trophy-alt": "eacf",
  "truck": "ead0",
  "twitter-square": "ead1",
  "ueaae-": "ead2",
  "umbrella-beach": "ead3",
  "unlock-alt": "ead4",
  "unlock": "ead5",
  "usd-circle": "ead6",
  "user-chart": "ead7",
  "user-check": "ead8",
  "user-circle": "ead9",
  "user-clock": "eada",
  "user-edit": "eadb",
  "user-headset": "eadc",
  "user-plus": "eadd",
  "user": "eade",
  "users": "eadf",
  "utensils": "eae0",
  "venus-mars": "eae1",
  "venus": "eae2",
  "video-plus": "eae3",
  "video-slash": "eae4",
  "video": "eae5",
  "volume-down": "eae6",
  "volume-mute": "eae7",
  "volume-up": "eae8",
  "walkie-talkie": "eae9",
  "water": "eaea",
  "wave": "eaeb",
  "whatsapp-2": "eaec",
  "whatsapp-square": "eaed",
  "whatsapp": "eaee",
  "wheelchair-move": "eaef",
  "wifi": "eaf0",
  "wind": "eaf1",
  "windows": "eaf2",
  "windsock": "eaf3",
  "windwindow-2": "eaf4",
  "windwindow": "eaf5",
  "world_map": "eaf6",
  "youtube-square": "eaf7",
  "youtube": "eaf8",
  "filter-list": "eaf9",
  "wand-magic-sparkles": "eafa",
  "location-crosshairs": "eafb",
  "tree-city": "eafc",
  "times-1": "eafd",
  "credit-card-1": "eafe",
  "paypal": "eaff",
  "apple-pay": "eb00",
  "list-ul": "eb05",
  "circle-exclamation": "eb06",
  "cloud-slash": "eb07",
  "training-hours": "eb08",
  "students": "eb09",
  "pro-courses": "eb0a",
  "teaching-hours": "eb0b",
  "Kitesurfing": "ea01",
);

@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

@each $name, $code in $icons {
  .icon-#{"" + $name}::before {
    content: unicode($code);
  }
}
