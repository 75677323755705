@use "sass:map";
@function retina-size($value) {
  @return math.floor($value / 2);
}

@mixin retina-bg-size($spriteWidth, $spriteHeight) {
  background-size: math.floor($spriteWidth / 2) math.floor($spriteHeight / 2);
}

.iti-flag {
  $item-width-maps: (
    ac: 21px,
    ad: 21px,
    ae: 21px,
    af: 21px,
    ag: 21px,
    ai: 21px,
    al: 21px,
    am: 21px,
    ao: 21px,
    aq: 21px,
    ar: 21px,
    as: 21px,
    at: 21px,
    au: 21px,
    aw: 21px,
    ax: 21px,
    az: 21px,
    ba: 21px,
    bb: 21px,
    bd: 21px,
    be: 19px,
    bf: 21px,
    bg: 21px,
    bh: 21px,
    bi: 21px,
    bj: 21px,
    bl: 21px,
    bm: 21px,
    bn: 21px,
    bo: 21px,
    bq: 21px,
    br: 21px,
    bs: 21px,
    bt: 21px,
    bv: 21px,
    bw: 21px,
    by: 21px,
    bz: 21px,
    ca: 21px,
    cc: 21px,
    cd: 21px,
    cf: 21px,
    cg: 21px,
    ch: 16px,
    ci: 21px,
    ck: 21px,
    cl: 21px,
    cm: 21px,
    cn: 21px,
    co: 21px,
    cp: 21px,
    cr: 21px,
    cu: 21px,
    cv: 21px,
    cw: 21px,
    cx: 21px,
    cy: 21px,
    cz: 21px,
    de: 21px,
    dg: 21px,
    dj: 21px,
    dk: 21px,
    dm: 21px,
    do: 21px,
    dz: 21px,
    ea: 21px,
    ec: 21px,
    ee: 21px,
    eg: 21px,
    eh: 21px,
    er: 21px,
    es: 21px,
    et: 21px,
    eu: 21px,
    fi: 21px,
    fj: 21px,
    fk: 21px,
    fm: 21px,
    fo: 21px,
    fr: 21px,
    ga: 21px,
    gb: 21px,
    gd: 21px,
    ge: 21px,
    gf: 21px,
    gg: 21px,
    gh: 21px,
    gi: 21px,
    gl: 21px,
    gm: 21px,
    gn: 21px,
    gp: 21px,
    gq: 21px,
    gr: 21px,
    gs: 21px,
    gt: 21px,
    gu: 21px,
    gw: 21px,
    gy: 21px,
    hk: 21px,
    hm: 21px,
    hn: 21px,
    hr: 21px,
    ht: 21px,
    hu: 21px,
    ic: 21px,
    id: 21px,
    ie: 21px,
    il: 21px,
    im: 21px,
    in: 21px,
    io: 21px,
    iq: 21px,
    ir: 21px,
    is: 21px,
    it: 21px,
    je: 21px,
    jm: 21px,
    jo: 21px,
    jp: 21px,
    ke: 21px,
    kg: 21px,
    kh: 21px,
    ki: 21px,
    km: 21px,
    kn: 21px,
    kp: 21px,
    kr: 21px,
    kw: 21px,
    ky: 21px,
    kz: 21px,
    la: 21px,
    lb: 21px,
    lc: 21px,
    li: 21px,
    lk: 21px,
    lr: 21px,
    ls: 21px,
    lt: 21px,
    lu: 21px,
    lv: 21px,
    ly: 21px,
    ma: 21px,
    mc: 20px,
    md: 21px,
    me: 21px,
    mf: 21px,
    mg: 21px,
    mh: 21px,
    mk: 21px,
    ml: 21px,
    mm: 21px,
    mn: 21px,
    mo: 21px,
    mp: 21px,
    mq: 21px,
    mr: 21px,
    ms: 21px,
    mt: 21px,
    mu: 21px,
    mv: 21px,
    mw: 21px,
    mx: 21px,
    my: 21px,
    mz: 21px,
    na: 21px,
    nc: 21px,
    ne: 19px,
    nf: 21px,
    ng: 21px,
    ni: 21px,
    nl: 21px,
    no: 21px,
    np: 14px,
    nr: 21px,
    nu: 21px,
    nz: 21px,
    om: 21px,
    pa: 21px,
    pe: 21px,
    pf: 21px,
    pg: 21px,
    ph: 21px,
    pk: 21px,
    pl: 21px,
    pm: 21px,
    pn: 21px,
    pr: 21px,
    ps: 21px,
    pt: 21px,
    pw: 21px,
    py: 21px,
    qa: 21px,
    re: 21px,
    ro: 21px,
    rs: 21px,
    ru: 21px,
    rw: 21px,
    sa: 21px,
    sb: 21px,
    sc: 21px,
    sd: 21px,
    se: 21px,
    sg: 21px,
    sh: 21px,
    si: 21px,
    sj: 21px,
    sk: 21px,
    sl: 21px,
    sm: 21px,
    sn: 21px,
    so: 21px,
    sr: 21px,
    ss: 21px,
    st: 21px,
    sv: 21px,
    sx: 21px,
    sy: 21px,
    sz: 21px,
    ta: 21px,
    tc: 21px,
    td: 21px,
    tf: 21px,
    tg: 21px,
    th: 21px,
    tj: 21px,
    tk: 21px,
    tl: 21px,
    tm: 21px,
    tn: 21px,
    to: 21px,
    tr: 21px,
    tt: 21px,
    tv: 21px,
    tw: 21px,
    tz: 21px,
    ua: 21px,
    ug: 21px,
    um: 21px,
    un: 21px,
    us: 21px,
    uy: 21px,
    uz: 21px,
    va: 16px,
    vc: 21px,
    ve: 21px,
    vg: 21px,
    vi: 21px,
    vn: 21px,
    vu: 21px,
    wf: 21px,
    ws: 21px,
    xk: 21px,
    ye: 21px,
    yt: 21px,
    za: 21px,
    zm: 21px,
    zw: 21px,
    cat: 21px,
  );
  $standard-country: "ac";

  width: map.get($item-width-maps, $standard-country);

  @each $key, $width in $item-width-maps {
    @if $width != map.get($item-width-maps, $standard-country) {
      &.#{$key} {
        width: $width;
      }
    }
  }

  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-size: 6220px 15px;
  }

  margin-right: 1px;
  height: 15px;
  // box-shadow: 0 0 1px 0 #888;
  background-image: url("/assets/images/flags/flags-3.15.21.webp");
  background-repeat: no-repeat;
  // empty state
  background-color: transparent;
  background-position: 20px 0;

  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("/assets/images/flags/flags-3.15.21@2x.webp");
  }

  &.iti-flag-select {
    position: absolute;
    left: 15px;
    top: 13px !important;
  }

  &.ac {
    height: 10px;
    background-position: 0 0;
  }

  &.ad {
    height: 14px;
    background-position: -22px 0;
  }

  &.ae {
    height: 10px;
    background-position: -44px 0;
  }

  &.af {
    height: 14px;
    background-position: -66px 0;
  }

  &.ag {
    height: 14px;
    background-position: -88px 0;
  }

  &.ai {
    height: 10px;
    background-position: -110px 0;
  }

  &.al {
    height: 15px;
    background-position: -132px 0;
  }

  &.am {
    height: 10px;
    background-position: -154px 0;
  }

  &.ao {
    height: 14px;
    background-position: -176px 0;
  }

  &.aq {
    height: 14px;
    background-position: -198px 0;
  }

  &.ar {
    height: 13px;
    background-position: -220px 0;
  }

  &.as {
    height: 10px;
    background-position: -242px 0;
  }

  &.at {
    height: 14px;
    background-position: -264px 0;
  }

  &.au {
    height: 10px;
    background-position: -286px 0;
  }

  &.aw {
    height: 14px;
    background-position: -308px 0;
  }

  &.ax {
    height: 13px;
    background-position: -330px 0;
  }

  &.az {
    height: 10px;
    background-position: -352px 0;
  }

  &.ba {
    height: 10px;
    background-position: -374px 0;
  }

  &.bb {
    height: 14px;
    background-position: -396px 0;
  }

  &.bd {
    height: 12px;
    background-position: -418px 0;
  }

  &.be {
    height: 15px;
    background-position: -440px 0;
  }

  &.bf {
    height: 14px;
    background-position: -460px 0;
  }

  &.bg {
    height: 12px;
    background-position: -482px 0;
  }

  &.bh {
    height: 12px;
    background-position: -504px 0;
  }

  &.bi {
    height: 12px;
    background-position: -526px 0;
  }

  &.bj {
    height: 14px;
    background-position: -548px 0;
  }

  &.bl {
    height: 14px;
    background-position: -570px 0;
  }

  &.bm {
    height: 10px;
    background-position: -592px 0;
  }

  &.bn {
    height: 10px;
    background-position: -614px 0;
  }

  &.bo {
    height: 14px;
    background-position: -636px 0;
  }

  &.bq {
    height: 14px;
    background-position: -658px 0;
  }

  &.br {
    height: 14px;
    background-position: -680px 0;
  }

  &.bs {
    height: 10px;
    background-position: -702px 0;
  }

  &.bt {
    height: 14px;
    background-position: -724px 0;
  }

  &.bv {
    height: 15px;
    background-position: -746px 0;
  }

  &.bw {
    height: 14px;
    background-position: -768px 0;
  }

  &.by {
    height: 10px;
    background-position: -790px 0;
  }

  &.bz {
    height: 14px;
    background-position: -812px 0;
  }

  &.ca {
    height: 10px;
    background-position: -834px 0;
  }

  &.cc {
    height: 10px;
    background-position: -856px 0;
  }

  &.cd {
    height: 15px;
    background-position: -878px 0;
  }

  &.cf {
    height: 14px;
    background-position: -900px 0;
  }

  &.cg {
    height: 14px;
    background-position: -922px 0;
  }

  &.ch {
    height: 15px;
    background-position: -944px 0;
  }

  &.ci {
    height: 14px;
    background-position: -961px 0;
  }

  &.ck {
    height: 10px;
    background-position: -983px 0;
  }

  &.cl {
    height: 14px;
    background-position: -1005px 0;
  }

  &.cm {
    height: 14px;
    background-position: -1027px 0;
  }

  &.cn {
    height: 14px;
    background-position: -1049px 0;
  }

  &.co {
    height: 14px;
    background-position: -1071px 0;
  }

  &.cp {
    height: 14px;
    background-position: -1093px 0;
  }

  &.cr {
    height: 12px;
    background-position: -1115px 0;
  }

  &.cu {
    height: 10px;
    background-position: -1137px 0;
  }

  &.cv {
    height: 12px;
    background-position: -1159px 0;
  }

  &.cw {
    height: 14px;
    background-position: -1181px 0;
  }

  &.cx {
    height: 10px;
    background-position: -1203px 0;
  }

  &.cy {
    height: 14px;
    background-position: -1225px 0;
  }

  &.cz {
    height: 14px;
    background-position: -1247px 0;
  }

  &.de {
    height: 15px;
    background-position: -1269px 0;
  }

  &.dg {
    height: 10px;
    background-position: -1291px 0;
  }

  &.dj {
    height: 14px;
    background-position: -1313px 0;
  }

  &.dk {
    height: 15px;
    background-position: -1335px 0;
  }

  &.dm {
    height: 10px;
    background-position: -1357px 0;
  }

  &.do {
    height: 14px;
    background-position: -1379px 0;
  }

  &.dz {
    height: 14px;
    background-position: -1401px 0;
  }

  &.ea {
    height: 14px;
    background-position: -1423px 0;
  }

  &.ec {
    height: 14px;
    background-position: -1445px 0;
  }

  &.ee {
    height: 13px;
    background-position: -1467px 0;
  }

  &.eg {
    height: 14px;
    background-position: -1489px 0;
  }

  &.eh {
    height: 10px;
    background-position: -1511px 0;
  }

  &.er {
    height: 10px;
    background-position: -1533px 0;
  }

  &.es {
    height: 14px;
    background-position: -1555px 0;
  }

  &.et {
    height: 10px;
    background-position: -1577px 0;
  }

  &.eu {
    height: 14px;
    background-position: -1599px 0;
  }

  &.fi {
    height: 12px;
    background-position: -1621px 0;
  }

  &.fj {
    height: 10px;
    background-position: -1643px 0;
  }

  &.fk {
    height: 10px;
    background-position: -1665px 0;
  }

  &.fm {
    height: 11px;
    background-position: -1687px 0;
  }

  &.fo {
    height: 15px;
    background-position: -1709px 0;
  }

  &.fr {
    height: 14px;
    background-position: -1731px 0;
  }

  &.ga {
    height: 15px;
    background-position: -1753px 0;
  }

  &.gb {
    height: 15px;
    background-position: -1776px 0;
  }

  &.gd {
    height: 12px;
    background-position: -1797px 0;
  }

  &.ge {
    height: 14px;
    background-position: -1819px 0;
  }

  &.gf {
    height: 14px;
    background-position: -1841px 0;
  }

  &.gg {
    height: 14px;
    background-position: -1863px 0;
  }

  &.gh {
    height: 14px;
    background-position: -1885px 0;
  }

  &.gi {
    height: 10px;
    background-position: -1907px 0;
  }

  &.gl {
    height: 14px;
    background-position: -1929px 0;
  }

  &.gm {
    height: 14px;
    background-position: -1951px 0;
  }

  &.gn {
    height: 14px;
    background-position: -1973px 0;
  }

  &.gp {
    height: 14px;
    background-position: -1995px 0;
  }

  &.gq {
    height: 14px;
    background-position: -2017px 0;
  }

  &.gr {
    height: 14px;
    background-position: -2039px 0;
  }

  &.gs {
    height: 10px;
    background-position: -2061px 0;
  }

  &.gt {
    height: 13px;
    background-position: -2083px 0;
  }

  &.gu {
    height: 11px;
    background-position: -2105px 0;
  }

  &.gw {
    height: 10px;
    background-position: -2127px 0;
  }

  &.gy {
    height: 12px;
    background-position: -2149px 0;
  }

  &.hk {
    height: 14px;
    background-position: -2171px 0;
  }

  &.hm {
    height: 10px;
    background-position: -2193px 0;
  }

  &.hn {
    height: 10px;
    background-position: -2215px 0;
  }

  &.hr {
    height: 10px;
    background-position: -2237px 0;
  }

  &.ht {
    height: 12px;
    background-position: -2259px 0;
  }

  &.hu {
    height: 10px;
    background-position: -2281px 0;
  }

  &.ic {
    height: 14px;
    background-position: -2303px 0;
  }

  &.id {
    height: 14px;
    background-position: -2325px 0;
  }

  &.ie {
    height: 10px;
    background-position: -2347px 0;
  }

  &.il {
    height: 15px;
    background-position: -2369px 0;
  }

  &.im {
    height: 10px;
    background-position: -2391px 0;
  }

  &.in {
    height: 14px;
    background-position: -2413px 0;
  }

  &.io {
    height: 10px;
    background-position: -2435px 0;
  }

  &.iq {
    height: 14px;
    background-position: -2457px 0;
  }

  &.ir {
    height: 12px;
    background-position: -2479px 0;
  }

  &.is {
    height: 15px;
    background-position: -2501px 0;
  }

  &.it {
    height: 14px;
    background-position: -2523px 0;
  }

  &.je {
    height: 12px;
    background-position: -2545px 0;
  }

  &.jm {
    height: 10px;
    background-position: -2567px 0;
  }

  &.jo {
    height: 10px;
    background-position: -2589px 0;
  }

  &.jp {
    height: 14px;
    background-position: -2611px 0;
  }

  &.ke {
    height: 14px;
    background-position: -2633px 0;
  }

  &.kg {
    height: 12px;
    background-position: -2655px 0;
  }

  &.kh {
    height: 13px;
    background-position: -2677px 0;
  }

  &.ki {
    height: 10px;
    background-position: -2699px 0;
  }

  &.km {
    height: 12px;
    background-position: -2721px 0;
  }

  &.kn {
    height: 14px;
    background-position: -2743px 0;
  }

  &.kp {
    height: 10px;
    background-position: -2765px 0;
  }

  &.kr {
    height: 14px;
    background-position: -2787px 0;
  }

  &.kw {
    height: 10px;
    background-position: -2809px 0;
  }

  &.ky {
    height: 10px;
    background-position: -2831px 0;
  }

  &.kz {
    height: 10px;
    background-position: -2853px 0;
  }

  &.la {
    height: 14px;
    background-position: -2875px 0;
  }

  &.lb {
    height: 14px;
    background-position: -2897px 0;
  }

  &.lc {
    height: 10px;
    background-position: -2919px 0;
  }

  &.li {
    height: 12px;
    background-position: -2941px 0;
  }

  &.lk {
    height: 10px;
    background-position: -2963px 0;
  }

  &.lr {
    height: 11px;
    background-position: -2985px 0;
  }

  &.ls {
    height: 14px;
    background-position: -3007px 0;
  }

  &.lt {
    height: 12px;
    background-position: -3029px 0;
  }

  &.lu {
    height: 12px;
    background-position: -3051px 0;
  }

  &.lv {
    height: 10px;
    background-position: -3073px 0;
  }

  &.ly {
    height: 10px;
    background-position: -3095px 0;
  }

  &.ma {
    height: 14px;
    background-position: -3117px 0;
  }

  &.mc {
    height: 15px;
    background-position: -3139px 0;
  }

  &.md {
    height: 10px;
    background-position: -3160px 0;
  }

  &.me {
    height: 10px;
    background-position: -3182px 0;
  }

  &.mf {
    height: 14px;
    background-position: -3204px 0;
  }

  &.mg {
    height: 14px;
    background-position: -3226px 0;
  }

  &.mh {
    height: 11px;
    background-position: -3248px 0;
  }

  &.mk {
    height: 10px;
    background-position: -3270px 0;
  }

  &.ml {
    height: 14px;
    background-position: -3292px 0;
  }

  &.mm {
    height: 14px;
    background-position: -3314px 0;
  }

  &.mn {
    height: 10px;
    background-position: -3336px 0;
  }

  &.mo {
    height: 14px;
    background-position: -3358px 0;
  }

  &.mp {
    height: 10px;
    background-position: -3380px 0;
  }

  &.mq {
    height: 14px;
    background-position: -3402px 0;
  }

  &.mr {
    height: 14px;
    background-position: -3424px 0;
  }

  &.ms {
    height: 10px;
    background-position: -3446px 0;
  }

  &.mt {
    height: 14px;
    background-position: -3468px 0;
  }

  &.mu {
    height: 14px;
    background-position: -3490px 0;
  }

  &.mv {
    height: 14px;
    background-position: -3512px 0;
  }

  &.mw {
    height: 14px;
    background-position: -3534px 0;
  }

  &.mx {
    height: 12px;
    background-position: -3556px 0;
  }

  &.my {
    height: 10px;
    background-position: -3578px 0;
  }

  &.mz {
    height: 14px;
    background-position: -3600px 0;
  }

  &.na {
    height: 14px;
    background-position: -3622px 0;
  }

  &.nc {
    height: 10px;
    background-position: -3644px 0;
  }

  &.ne {
    height: 15px;
    background-position: -3666px 0;
  }

  &.nf {
    height: 10px;
    background-position: -3686px 0;
  }

  &.ng {
    height: 10px;
    background-position: -3708px 0;
  }

  &.ni {
    height: 12px;
    background-position: -3730px 0;
  }

  &.nl {
    height: 14px;
    background-position: -3752px 0;
  }

  &.no {
    height: 15px;
    background-position: -3774px 0;
  }

  &.np {
    height: 15px;
    background-position: -3796px 0;
  }

  &.nr {
    height: 10px;
    background-position: -3811px 0;
  }

  &.nu {
    height: 10px;
    background-position: -3833px 0;
  }

  &.nz {
    height: 10px;
    background-position: -3855px 0;
  }

  &.om {
    height: 10px;
    background-position: -3877px 0;
  }

  &.pa {
    height: 14px;
    background-position: -3899px 0;
  }

  &.pe {
    height: 14px;
    background-position: -3921px 0;
  }

  &.pf {
    height: 14px;
    background-position: -3943px 0;
  }

  &.pg {
    height: 15px;
    background-position: -3965px 0;
  }

  &.ph {
    height: 10px;
    background-position: -3987px 0;
  }

  &.pk {
    height: 14px;
    background-position: -4009px 0;
  }

  &.pl {
    height: 13px;
    background-position: -4031px 0;
  }

  &.pm {
    height: 14px;
    background-position: -4053px 0;
  }

  &.pn {
    height: 10px;
    background-position: -4075px 0;
  }

  &.pr {
    height: 14px;
    background-position: -4097px 0;
  }

  &.ps {
    height: 10px;
    background-position: -4119px 0;
  }

  &.pt {
    height: 14px;
    background-position: -4141px 0;
  }

  &.pw {
    height: 13px;
    background-position: -4163px 0;
  }

  &.py {
    height: 11px;
    background-position: -4185px 0;
  }

  &.qa {
    height: 8px;
    background-position: -4207px 0;
  }

  &.re {
    height: 14px;
    background-position: -4229px 0;
  }

  &.ro {
    height: 14px;
    background-position: -4251px 0;
  }

  &.rs {
    height: 14px;
    background-position: -4273px 0;
  }

  &.ru {
    height: 14px;
    background-position: -4295px 0;
  }

  &.rw {
    height: 14px;
    background-position: -4317px 0;
  }

  &.sa {
    height: 14px;
    background-position: -4339px 0;
  }

  &.sb {
    height: 10px;
    background-position: -4361px 0;
  }

  &.sc {
    height: 10px;
    background-position: -4383px 0;
  }

  &.sd {
    height: 10px;
    background-position: -4405px 0;
  }

  &.se {
    height: 13px;
    background-position: -4427px 0;
  }

  &.sg {
    height: 14px;
    background-position: -4449px 0;
  }

  &.sh {
    height: 10px;
    background-position: -4471px 0;
  }

  &.si {
    height: 10px;
    background-position: -4493px 0;
  }

  &.sj {
    height: 15px;
    background-position: -4515px 0;
  }

  &.sk {
    height: 14px;
    background-position: -4537px 0;
  }

  &.sl {
    height: 14px;
    background-position: -4559px 0;
  }

  &.sm {
    height: 15px;
    background-position: -4581px 0;
  }

  &.sn {
    height: 14px;
    background-position: -4603px 0;
  }

  &.so {
    height: 14px;
    background-position: -4625px 0;
  }

  &.sr {
    height: 14px;
    background-position: -4647px 0;
  }

  &.ss {
    height: 10px;
    background-position: -4669px 0;
  }

  &.st {
    height: 10px;
    background-position: -4691px 0;
  }

  &.sv {
    height: 12px;
    background-position: -4713px 0;
  }

  &.sx {
    height: 14px;
    background-position: -4735px 0;
  }

  &.sy {
    height: 14px;
    background-position: -4757px 0;
  }

  &.sz {
    height: 14px;
    background-position: -4779px 0;
  }

  &.ta {
    height: 10px;
    background-position: -4801px 0;
  }

  &.tc {
    height: 10px;
    background-position: -4823px 0;
  }

  &.td {
    height: 14px;
    background-position: -4845px 0;
  }

  &.tf {
    height: 14px;
    background-position: -4867px 0;
  }

  &.tg {
    height: 13px;
    background-position: -4889px 0;
  }

  &.th {
    height: 14px;
    background-position: -4911px 0;
  }

  &.tj {
    height: 10px;
    background-position: -4933px 0;
  }

  &.tk {
    height: 10px;
    background-position: -4955px 0;
  }

  &.tl {
    height: 10px;
    background-position: -4977px 0;
  }

  &.tm {
    height: 14px;
    background-position: -4999px 0;
  }

  &.tn {
    height: 14px;
    background-position: -5021px 0;
  }

  &.to {
    height: 10px;
    background-position: -5043px 0;
  }

  &.tr {
    height: 14px;
    background-position: -5065px 0;
  }

  &.tt {
    height: 12px;
    background-position: -5087px 0;
  }

  &.tv {
    height: 10px;
    background-position: -5109px 0;
  }

  &.tw {
    height: 14px;
    background-position: -5131px 0;
  }

  &.tz {
    height: 14px;
    background-position: -5153px 0;
  }

  &.ua {
    height: 14px;
    background-position: -5175px 0;
  }

  &.ug {
    height: 14px;
    background-position: -5197px 0;
  }

  &.um {
    height: 11px;
    background-position: -5219px 0;
  }

  &.un {
    height: 14px;
    background-position: -5241px 0;
  }

  &.us {
    height: 11px;
    background-position: -5263px 0;
  }

  &.uy {
    height: 14px;
    background-position: -5285px 0;
  }

  &.uz {
    height: 10px;
    background-position: -5307px 0;
  }

  &.va {
    height: 15px;
    background-position: -5329px 0;
  }

  &.vc {
    height: 14px;
    background-position: -5346px 0;
  }

  &.ve {
    height: 14px;
    background-position: -5368px 0;
  }

  &.vg {
    height: 10px;
    background-position: -5390px 0;
  }

  &.vi {
    height: 14px;
    background-position: -5412px 0;
  }

  &.vn {
    height: 14px;
    background-position: -5434px 0;
  }

  &.vu {
    height: 12px;
    background-position: -5456px 0;
  }

  &.wf {
    height: 14px;
    background-position: -5478px 0;
  }

  &.ws {
    height: 10px;
    background-position: -5500px 0;
  }

  &.xk {
    height: 15px;
    background-position: -5522px 0;
  }

  &.ye {
    height: 14px;
    background-position: -5544px 0;
  }

  &.yt {
    height: 14px;
    background-position: -5566px 0;
  }

  &.za {
    height: 14px;
    background-position: -5588px 0;
  }

  &.zm {
    height: 14px;
    background-position: -5610px 0;
  }

  &.zw {
    height: 10px;
    background-position: -5632px 0;
  }

  &.cat {
    height: 15px;
    background-position: -5657px 0;
  }
}

.btn-sm {
  .iti-flag {
    &.iti-flag-select {
      top: 8px;
    }
  }
}

li {
  &.country:hover {
    background-color: rgb(0 0 0 / 5%);
  }
}

.no-webp {
  .iti-flag {
    background-image: url("/assets/images/flags/flags-3.15.21.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("/assets/images/flags/flags-3.15.21@2x.png");
    }
  }
}
