// helper for font-size

@for $i from 1 through 120 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .h-cs-#{$i} {
    min-height: #{$i}vh;
    max-height: #{$i}vh;
  }

  .w-cs-#{$i} {
    width: #{$i}#{"%"} !important;
  }
}

// helper for underline text
.text-decoration-underline {
  text-decoration: underline;
}

.text-red {
  color: #ef233c;
}

.text-right {
  text-align: right;
}
