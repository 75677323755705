@keyframes modal-fly-in-out {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.modal {
  top: -100px;
  animation: ease-in-out 0.5s modal-fly-in-out;
  transition: all 0.5s ease-in-out;

  &.show {
    top: 0;
  }
}

.loading {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 2s ease-in-out infinite;
  min-height: 150px;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
