/*           badges             */
.badge {
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: $font-size-mini;
  line-height: 12px;
  background-color: $transparent-bg;
  border: $border;
  margin-bottom: 5px;
  color: $white-color;
  border-radius: $border-radius-extreme;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.badge-icon {
  padding: 0.4em 0.55em;
  i {
    font-size: max(0.8em, 12px);
  }
}
.badge-default {
  @include badge-color($default-color);
}
.badge-primary {
  @include badge-color($primary-color);
}
.badge-info {
  @include badge-color($info-color);
}
.badge-success {
  @include badge-color($success-color);
}
.badge-warning {
  @include badge-color($warning-color);
}
.badge-danger {
  @include badge-color($danger-color);
}
.badge-neutral {
  @include badge-color($white-color);
  color: inherit !important;
}
