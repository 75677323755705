.dropdown-menu {
  i {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  .now-ui-icons {
    margin-right: 10px;
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-top: -5px;
    opacity: 0.5;
  }

  .dropdown-item {
    &.active,
    &:active {
      color: inherit;
    }
  }

  .dropup & {
    &:before {
      display: none;
    }

    &:after {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: auto;
      bottom: -5px;
      right: auto;
      left: 10px;
      color: $white-color;
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
    }

    &.dropdown-menu-right {
      &:after {
        right: 10px;
        left: auto;
      }
    }
  }

  .dropdown-item,
  .bootstrap-select &.inner li a {
    font-size: $font-size-small;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    @include transition($fast-transition-time, $transition-linear);

    &:hover,
    &:focus {
      background-color: $opacity-gray-3;
    }

    &.disabled,
    &:disabled {
      color: $default-color-opacity;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  // Dropdown
  .dropdown &:not(.inner),
  &.bootstrap-datetimepicker-widget {
    @include transform-translate-y-dropdown(-25px);
    visibility: hidden;
    display: block;
    @include opacity(0);
    top: 100% !important;
  }

  .dropdown.show &:not(.inner),
  &.bootstrap-datetimepicker-widget.bottom.open,
  &.bootstrap-datetimepicker-widget.top.open {
    @include opacity(1);
    visibility: visible;
    @include transform-translate-y-dropdown(0px);
  }

  // Dropup
  .dropup &:not(.inner),
  &.bootstrap-datetimepicker-widget.top {
    visibility: hidden;
    @include opacity(0);
    @include transform-translate-y-dropdown(25px);
    display: block;
    top: auto !important;
    bottom: 100% !important;
  }

  .dropup.show &:not(.inner),
  &.bootstrap-datetimepicker-widget.top.open {
    visibility: visible;
    @include opacity(1);
    @include transform-translate-y-dropdown(-1px);
  }
}
