.card-blog {
  .row {
    .category {
      margin-bottom: 0;
    }
  }

  .card-image + .category {
    margin-top: 20px;
  }
}
