@use "sass:math";
@use "sass:map";

$icon-size: 14;

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
  }

  .overflow-scroll {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  // .tab-content {
  //   height: 100% !important;
  // }

  // .tab-content,
  // .tab-pane {
  //   position: relative;
  //   display: flex !important;
  //   flex-grow: 1;
  //   flex-direction: column;
  // }
}

@media screen and (min-width: 1025px) {
  .overflow-scroll {
    overflow: overlay !important;
    overflow-x: hidden !important;
  }

  ::-webkit-scrollbar-track {
    background-color: rgb(0 0 0 / 15%);
  }

  * {
    scrollbar-color: rgb(0 0 0 / 15%);
    scrollbar-width: 7px;
  }

  ::-webkit-scrollbar {
    appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(0 0 0 / 25%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }
}

@media screen and (max-width: 400px) {
  .w-cs-100 {
    width: 100%;
  }
}

.extra-small {
  font-size: max(65%, 12px);
}

* {
  font-family: OpenSans;
}

.display-scroll,
.epub-container {
  &::-webkit-scrollbar {
    display: block !important;
    width: 7px !important;
    background: #dedede;
  }

  /* optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 25%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }

  -ms-overflow-style: scrollbar !important;
  overflow: -moz-scrollbars-vertical !important;
}

aside {
  // box-shadow: black 1px 0px 2px 0px;
  max-width: 80%;
  width: 330px;
}

.bg-grey {
  background-color: #dee2e6;
}

.rotate-90 {
  transform: rotate(90deg);
}

.tb-0 {
  top: 0;
  bottom: 0;
}

// .card {
//   color: $white;
// }

.card-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content-outlet + * {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floating-arrow-right {
  right: 40px;
}

a,
button,
.pointer {
  cursor: pointer !important;
}

.big {
  font-size: 17.4px;
}
@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/opensans.woff2") format("woff2"), url("/assets/fonts/opensans.woff") format("woff");
  font-display: fallback;
}

@for $i from 1 through 10 {
  .bg-light-transparent-#{$i} {
    background-color: rgba(248, 249, 250, math.div($i, 10)) !important;
  }
}

@for $i from 0 through 8 {
  .bottom-#{$i} {
    bottom: #{$i * 10}px;
  }
  .top-#{$i} {
    top: #{$i * 10}px;
  }
  .right-#{$i} {
    right: #{$i * 10}px;
  }
  .left-#{$i} {
    left: #{$i * 10}px;
  }
}

@for $i from 1 through 5 {
  .rounded-#{$i} {
    border-radius: #{$i * 10}px !important;
  }
}

@for $i from -1 through 5 {
  .z-index-#{$i} {
    z-index: $i;
  }
}

@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: math.div($i, 10);
  }
}

.placeholder-white {
  &::placeholder {
    color: $white !important;
  }
}

.placeholder-primary {
  &::placeholder {
    color: $primary !important;
  }
}

.lr-0 {
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.box-shadow {
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 30%);
}

.border-default {
  border-color: $light-gray !important;
}

.tabs {
  &.hori-tabs {
    .nav-tabs {
      display: flex;
    }

    .nav-item .nav-link {
      padding: 5px;
      margin-bottom: 0;
      box-shadow: none !important;
    }
  }

  &.white-tabs {
    .nav-tabs {
      display: flex;
    }

    .nav-item .nav-link {
      padding: 5px;
      margin-bottom: 0;
      box-shadow: none !important;
    }
  }

  &.tabs-secondary {
    .nav-item .nav-link {
      background-color: $secondary;
    }
  }

  &.tabs-info {
    .nav-item .nav-link {
      background-color: $white;
    }
  }

  &.tabs-primary {
    .nav-item .nav-link {
      background-color: $primary;
    }
  }

  &.tabs-active-success {
    .nav-item .nav-link {
      &.active {
        background-color: $success;
      }
    }
  }

  &.tabs-active-border {
    .nav-item .nav-link {
      &.active {
        background-color: $primary;
        border-color: $light;
        border-width: 2px;
      }
    }
  }

  &.tabs-border-default {
    .nav-item .nav-link,
    .nav-item .nav-link.active {
      margin-right: 0;
    }
  }

  &.tabs-content-padding-0 {
    .tab-content {
      padding: 0 !important;
    }
  }
}

.bg-f0 {
  background-color: #f0f0f0;
}

.no-label {
  padding-top: 40px;
}

.no-top-padding {
  margin-top: -0.5rem;
}

.bg-primary-reverse-gradient-transparent {
  background-image: linear-gradient(
    to bottom,
    rgba(#001930, 0.5),
    rgba(#002950, 0.5),
    rgba($primary, 0.7),
    rgba($primary, 0.7)
  );
}

.bg-primary-gradient-transparent {
  background-image: linear-gradient(
    to bottom,
    rgba($primary, 0.7),
    rgba($primary, 0.7),
    rgba(#002950, 0.5),
    rgba(#001930, 0.5)
  );
}

.bg-dark-transparent {
  background-color: rgb(0 0 0 / 50%);
}

.bg-dark-transparent-25 {
  background-color: rgb(0 0 0 / 25%) !important;
}

.brightness-50 {
  filter: brightness(30%);
}

.bg-transparent {
  background-color: transparent !important;

  .hide-in-trasparent {
    display: none;
  }
}

.progress-secondary {
  .progress-bar {
    background-color: $secondary-color !important;
  }
}

.green-bg-gradient {
  background-image: linear-gradient(
    to bottom,
    rgb(112 180 100 / 80%),
    rgb(67 120 58 / 80%),
    rgb(67 120 58 / 50%),
    rgb(67 120 58 / 30%)
  );
}

.black-bg-gradient {
  filter: grayscale(1);
}

.bg-primary-gradient {
  background-image: linear-gradient(to bottom, $primary, $primary, #003a72, #002950, #001930);
}

.icon-30 {
  font-size: 30px !important;
}

.icon-64 {
  font-size: 64px !important;
}

.icon-128 {
  font-size: 128px !important;
}

.icon-100 {
  font-size: 100px !important;
}

.icon-150 {
  font-size: 150px !important;
}

.icon-55 {
  font-size: 55px !important;
}

.icon-facebook-square:hover,
.icon-instagram:hover {
  text-decoration: none;
  color: inherit;
}

.floating-card {
  inset: $header-height 0 0 auto;
  width: 23rem;

  &.mobile {
    width: 100vw;
    height: 101vh;
    top: 0;
    left: 0;
  }
}

.overlay {
  &.green {
    background-image: linear-gradient(rgb(30 158 52 / 60%), rgb(30 158 52 / 40%));
  }

  &.blue {
    background-image: linear-gradient(rgb(11 55 138 / 60%), rgb(11 55 138 / 40%));
  }

  &.orange {
    background-image: linear-gradient(rgb(218 92 42 / 60%), rgb(218 92 42 / 40%));
  }

  &.gray {
    background-image: linear-gradient(rgb(68 67 67 / 60%), rgb(68 67 67 / 40%));
  }
}

.divider {
  width: 100%;
  height: 2px;

  .circle-dot {
    margin-top: -4px;
  }

  &.gray {
    background-color: $gray-400;
  }

  &.primary {
    background-color: $primary;
  }

  &.success {
    background-color: $success;
  }
}

.dropdown {
  .select-right-icon {
    right: 23px;
    top: 10px;
  }
}

bswitch {
  &.on-green {
    .bootstrap-switch {
      .bootstrap-switch-label {
        background: map.get($theme-colors, secondary);
      }
    }
  }

  &.off-danger {
    .bootstrap-switch {
      &.bootstrap-switch-off .bootstrap-switch-label {
        background: map.get($theme-colors, danger);
      }
    }
  }
}

.circle-dot {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 50%;

  &.circle-dot-big {
    width: 15px;
    height: 15px;
  }
}

#scrollable {
  overflow: scroll;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  display: inline-block;
  position: absolute;
  top: 10px;

  &.arrow-left-primary {
    border-right: 15px solid $primary;
    right: -2px;
  }

  &.arrow-right-light {
    border-left: 15px solid $gray-100;
    left: -2px;
  }
}

.h-1 {
  height: 1px;
}

.menu-item-active {
  transition: all 0.3s;
  color: $primary;
  background: $white;
}

.custom-slider {
  // color

  // main style
  input[type="range"] {
    height: 21px;
    appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    // animate: 0.2s;
    box-shadow: 0 0 0 $black;
    background: $grey-varient-10;
    border-radius: 3px;
    border: 0 solid $black;
  }

  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0 0 2px $black;
    border: 0 solid $black;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    background: $white;
    cursor: pointer;
    appearance: none;
    margin-top: -6px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $grey-varient-10;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    // animate: 0.2s;
    box-shadow: 0 0 0 $black;
    background: $grey-varient-10;
    border-radius: 3px;
    border: 0 solid $black;
  }

  input[type="range"]::-moz-range-thumb {
    box-shadow: 0 0 2px $black;
    border: 0 solid $black;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    background: $white;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    // animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type="range"]::-ms-fill-lower {
    background: $grey-varient-10;
    border: 0 solid $black;
    border-radius: 6px;
    box-shadow: 0 0 0 $black;
  }

  input[type="range"]::-ms-fill-upper {
    background: $grey-varient-10;
    border: 0 solid $black;
    border-radius: 6px;
    box-shadow: 0 0 0 $black;
  }

  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 0 2px $black;
    border: 0 solid $black;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    background: $white;
    cursor: pointer;
  }

  input[type="range"]:focus::-ms-fill-lower {
    background: $grey-varient-10;
  }

  input[type="range"]:focus::-ms-fill-upper {
    background: $grey-varient-10;
  }
}

.success-checkbox-border {
  .form-check-sign::before,
  .form-check-sign::after {
    border: 1px solid $success !important;
  }
}

.text-shadow {
  text-shadow: 0 0 5px rgb(0 0 0 / 50%);
}

.modal-sm-centered {
  .modal-sm {
    margin-top: 30%;
    margin-left: 15%;
    margin-right: 15%;
  }
}

.modal-backdrop {
  background: #00000036;
  backdrop-filter: blur(3px);
  opacity: 1 !important;
}

.modal-content,
.modal-content > div {
  @media screen and (min-width: 992px) {
    border-radius: 30px;
  }
}

.min-height-120 {
  min-height: 120px;
}

.min-height-135 {
  min-height: 135px;
}

.min-height-100 {
  min-height: 100px;
}

.boxshadow-right-white {
  box-shadow: 0 0 2px #aaa;
}

.bg-no-repeate {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-centered {
  background-position: center center;
}

.feedbackForm.tabs.hori-tabs ul.nav.nav-tabs.nav-fill {
  display: none !important;
}

.card-transparent-bg {
  background: linear-gradient(0deg, rgb(0 94 184 / 85%) 0%, rgb(255 255 255 / 0%) 40%, rgb(255 255 255 / 0%) 100%);
}

.card-transparent {
  &.green {
    background: linear-gradient(0deg, rgb(30 158 52 / 60%) 0%, rgb(255 255 255 / 0%) 40%, rgb(255 255 255 / 0%) 100%);
  }
}

.light-bg-color-f2 {
  background: #f2f2f2;
}

.r-0 {
  right: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.light-gray-background {
  background-color: $light-gray;
}

.min-height-300 {
  min-height: 300px;
}

.custom-radio.active {
  background-color: $primary-color !important;
  color: white !important;
}

.bg-gray {
  background-color: $gray-500;
}

.bg-light-gray {
  background-color: $light-gray;
}

image-srcset {
  display: block;
}

ckeditor .ck-content {
  min-height: 200px;
}

strong {
  font-weight: bold;
}

#btn-login-apple {
  background: #f3f3f3 !important;
  color: black !important;
}

.hide-scrollbar {
  overflow: -moz-hidden-unscrollable !important;
  overflow: hidden !important;
}

circle-progress svg image {
  clip-path: circle(49%);
}

.btn-download-app {
  color: $white;
  background-color: $black;
  font-size: 1.1rem;
  border-radius: 0.45rem;
  padding: 0.55rem 0.75rem;
  line-height: 1.5;
  transform: all 0.5s ease-in-out;

  &:hover {
    color: $white !important;
    background-color: $black !important;
  }
}

.btn-download-app .icon {
  font-size: 2.25rem;
}

$padding-standard: 10px;

.header-detail-page {
  z-index: 0;
  position: relative;
  margin-bottom: $padding-standard;
  width: 100%;
  height: 280px;
  @media screen and (max-width: 768px) {
    height: 156px;
  }

  .bg-light-grey {
    background-color: #f2f2f2;
    min-height: 160px;
    width: 100%;
    @media screen and (max-width: 767.98px) {
      min-height: 120px;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      min-height: 144px;
    }
  }

  .header-buttons {
    .center-info-content {
      @media screen and (max-width: 768px) {
        button {
          margin: 5px 1px;
        }
      }
    }
  }
}

.button-navigation-swiper {
  top: calc(50% - 52px);
  z-index: 5;
  color: $success-color;
  // padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  &.disabled {
    opacity: 0.5;
  }

  &.button-prev {
    padding-left: 0;
  }

  &.button-next {
    padding-right: 0;
  }
}

@for $i from 1 through 350 {
  .width-#{$i} {
    width: #{$i}px;
  }

  .height-#{$i} {
    height: #{$i}px;
  }

  .max-width-#{$i} {
    max-width: #{$i}px;
  }
}

.speech-bubble {
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-top: -10px;
    top: 50%;
    border: 12px solid transparent;
  }

  &.message-me::after {
    right: 0;
    border-left-color: $gray-100;
    border-right: 0;
    margin-right: 5px;
  }

  &.message-others::after {
    left: 0;
    border-right-color: $primary-color;
    border-left: 0;
    margin-left: 5px;
  }
}

.small-popup {
  max-height: 240px !important;
  overflow-y: overlay !important;
}

.pro-tag {
  display: inline;
  vertical-align: top;
  line-height: 40px;
}

.pro-tag span {
  background: #00003f;
  color: $white;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  font-weight: 800;
  box-shadow: rgb(0 0 0 / 18%) 0 2px 4px;
}

.btn-green {
  background-color: var(--bs-green) !important;
  border-color: var(--bs-green) !important;
  color: white;
}

.footer-separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgb(0 0 0 / 10%) !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  inset: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  position: absolute;
  width: auto;
}

.word-break-normal {
  word-break: normal !important;
}

.limit-height-dropdown {
  max-height: 450px;
}

.text-grey {
  color: #737373;
}

dynamic-ng-bootstrap-checkbox-group,
dynamic-ng-bootstrap-checkbox {
  .btn-group-toggle,
  .form-check {
    label {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.hover-shadow,
.card {
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 50%);
  }
}

.hover-rounded-1 {
  &:hover {
    border-radius: 10px !important;
  }
}

.hover-rounded-top {
  &:hover {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

// fixing deprecations in bootstrap 5
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
}

.input-group
  > :not(:first-child, .dropdown-menu)
  :not(.valid-tooltip, .valid-feedback, .invalid-tooltip, .invalid-feedback) {
  margin-left: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
}

small,
.small {
  font-size: max(79%, 12px);
}

button.close {
  background: transparent;
  border: none;
  font-size: 1.4rem;
}

ngb-accordion {
  div.accordion-item {
    background-color: rgb(248 249 250 / 40%) !important;

    .accordion-button {
      background: transparent;
      padding-inline: 10px;

      &::after {
        display: none;
      }

      > div {
        text-align: left;
        width: 100%;
        height: 100%;
      }

      &.collapsed {
        .icon-angle-up {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.accordion-header {
  .accordion-button:focus,
  .accordion-button:not(.collapsed) {
    border: none;
    box-shadow: none;
  }
}

.text-justify {
  text-align: justify !important;
}

// fix radio inputs with dynamic forms 14 and bootstrap 5 as dynamic forms doesn't support bootstrap 5 yet.
input[type="radio"] {
  display: none;
}

.modal-body.filters {
  background-color: rgb(246 248 251);
}

.is-loading {
  ngb-typeahead-window.dropdown-menu.show {
    display: none;
  }
}

.max-width-body {
  max-width: 1408px;
}

.sticky-card {
  position: sticky;
  top: 0;
}

$cursor-status: alias all-scroll auto col-resize context-menu copy crosshair default e-resize ew-resize grab grabbing
  help move n-resize ne-resize nesw-resize ns-resize nwse-resize no-drop none pointer progress row-resize s-resize
  se-resize sw-resize text url w-resize wait zoom-in zoom-out;

@each $current-status in $cursor-status {
  .cursor-#{$current-status} {
    cursor: #{$current-status} !important;
  }
}

$borders-sides: top right bottom left;

@each $border-side in $borders-sides {
  @if $border-side == "left" or $border-side == "right" {
    .round-border-#{$border-side} {
      border-top-#{$border-side}-radius: 30px !important;
      border-bottom-#{$border-side}-radius: 30px !important;
    }
  }

  .no-border-#{$border-side} {
    &:active,
    &:hover,
    &:focus {
      border-#{$border-side}-width: 0 !important;
    }

    border-#{$border-side}-width: 0 !important;
  }
}

.input-group.is-invalid {
  > * {
    border-color: #dc3545;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.fab-button {
  &.fab-mini {
    width: 40px;
    height: 40px;

    > * {
      font-size: 28px !important;
    }
  }

  &.fab-micro {
    width: 28px;
    height: 28px;

    > * {
      font-size: 16px !important;
    }
  }

  border-radius: 50%;
  width: 56px;
  height: 56px;

  > * {
    font-size: 42px !important;
  }

  position: fixed;
  z-index: 5;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.no-hover {
  pointer-events: none;
}

.button-slider {
  font-size: 1.4rem;
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: block;
}

.drodpdown-buttons-filters {
  button {
    min-width: 112px;
    text-align: center;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

iframe {
  border-radius: 10px;
}

.list-actions {
  .box-shadow {
    width: 52px;
    color: $success-color;
    background-color: white;
    transition: all 0.3s 0s ease-in-out;

    &.selected {
      color: white;
      background-color: $success-color;
    }
  }

  .icon-left {
    border-radius: 50% 0 0 50%;
  }

  .icon-right {
    border-radius: 0 50% 50% 0;
  }
}

.grey-background {
  filter: grayscale(100%);
}

.br-default {
  --br-font-size: 16px;
}

// member detail page
.member-card {
  .br-default {
    --br-font-size: 25px;
  }
}

.br-default .br-unit-inner::before {
  content: "✰";
  color: var(--br-active-color);
}

.br-default .br-active::before {
  content: "★";
}

.filters-wrapper .text-info {
  font-size: max(0.8571em, 12px);
}

.search-wrapper .col-md-4 {
  margin: 10px 0;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 4;
}

// safari adding shadow element cause date picker to seems autocomplete
#quality_feedback_section input[type="date"]::-webkit-datetime-edit {
  -webkit-opacity: var(--field-opacity, 0);
}

input[type="date"].has-value {
  --field-opacity: 1;
}
// new class for input error
.not-valid-field {
  > * {
    border: none !important;
    box-shadow: none !important;
  }

  border: 1px solid rgb(220 53 69) !important;
  border-radius: 30px;
}

.course-info-window {
  width: 98%;
  display: block;
  margin: auto !important;

  .card {
    box-shadow: none !important;
  }
}

.clip-path-circle {
  clip-path: circle();
}

.mw-500 {
  max-width: 500px;
}
