.social-line-big-icons {
  [class*="col-"] {
    border-right: 1px solid $light-gray;

    &:last-child {
      border: 0;
    }
  }

  .btn {
    margin: 0;
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    height: auto;
    border: 0;

    .fab {
      font-size: 25px;
    }
  }
}

.social-line {
  padding: 0.85rem 0;
}

.subscribe-line {
  padding: 15px * 2 0;

  .card {
    margin-top: 54px;

    .card-block {
      min-height: auto;
    }
  }
  &.subscribe-line-image {
    position: relative;

    background-position: center center;
    background-size: cover;

    .description {
      color: $opacity-8;
    }

    .title {
      color: $white-color;
    }
    &:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.66);
    }

    .container {
      z-index: 2;
      position: relative;
    }
  }
}

.social-line-white,
.subscribe-line-white {
  background-color: $white-color;
}
.social-line-black,
.subscribe-line-black {
  background-color: $black-color;

  [class*="col-"] {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

[data-background-color] {
  .social-line {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: initial;
    }

    .btn.btn-simple {
      border: 0;
    }
  }
}
