/*
 * Application global variables.
 */

// ---------------------------------------------------------------------------
// Bootstrap variables
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

// Color system
@use "sass:map";

$primary-color: #005eb8;
$primary-dark-color: #03498b;
$secondary-color: #28a745;
$success-color: #f60;
$info-color: #969696;
$light-gray: #ebebe9;
$header-height: 60px;

$primary: $primary-color;
$secondary: $secondary-color;
$success: $success-color;
$info: $info-color;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: $light-gray;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: $info-color;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #1c2b36;
$black: #000;

$blue: $primary-color;
$indigo: #6610f2;
$purple: #6f42c1;
$purple-dark: rgb(84 40 128 / 84.3%);
$pink: #e83e8c;
$red: #dc3545;
$orange: $success-color;
$yellow: #ffc107;
$green: $secondary-color;
$light-green: #41a50b;
$teal: #20c997;
$cyan: #17a2b8;
// newly added
$vcard-blue: $primary-dark-color;
$grey-varient-1: #d2d2d2;
$grey-varient-2: #d9d9d9;
$grey-varient-3: #58595b;
$grey-varient-4: #e1e1e1;
$grey-varient-5: #444;
$grey-varient-6: #d8d8d8;
$grey-varient-7: #6c757d;
$grey-varient-8: #2c2c2c;
$grey-varient-9: #ccc;
$grey-varient-10: #d6d6d6;
$grey-varient-11: #eee;
$black-varient-1: #1f1f1f;
$orange-varient-1: #fd7e16;
$orange-varient-2: #fd7e16;
$orange-varient-3: #f2b01e;
$orange-varient-4: #ff781f;
$blue-varient-1: #5466bf;
$blue-varient-2: #065eb6;
$ink-blue: #00003f;
$green-varient-1: #b6ff00;

$theme-colors: (
  "primary": $blue,
  "secondary": $green,
  "success": $orange,
  "info": $gray-600,
  "warning": $yellow,
  "danger": $red,
  "light": $gray-100,
  "dark": $gray-800,
  "green": $green,
  "light-green": $light-green,
);

$enable-gradients: true;
// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
// add more spacer 6
$spacers: map.set($spacers, 6, $spacer * 5);

$enable-negative-margins: true;

:root {
  --bar-fixed-message-height: 25px;
}
