.navbar-toggler-bar {
  height: 2px;
}

.dropdown {
  &:not(.show) {
    .dropdown-menu {
      display: none;
    }
  }

  &.dropdown-icon-hidden {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  .dropdown-menu {
    margin-bottom: 15px;
    // max-height: 350px;
    overflow-y: overlay;
    left: 0 !important;

    &.dropdown-language {
      left: auto !important;
      right: 0 !important;
      max-height: 42vh;
    }

    &::before {
      left: 0 !important;
      right: 0 !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.dropdown-icon-right {
  .dropdown-toggle {
    &::after {
      float: right;
      margin-top: 5px;
    }
  }
}

.hori-tabs {
  .nav-tabs {
    background-color: $primary;
    padding: 15px;
  }
}

.white-tabs {
  .nav-tabs {
    background-color: $white;
    padding: 15px;
  }
}

.progress-container {
  .progress {
    height: 3px !important;
    background-color: $light !important;
  }
}

.border-light-gray {
  border-color: $light-gray !important;
}

.form-check {
  &:not(.form-check-radio) {
    .form-check-sign::after {
      font-family: "IKO_font" !important;
      content: "\ea31" !important;
    }
  }
}

@media (max-width: 736px) {
  .modal-fullscreen {
    display: flex !important;
    flex-grow: 1;
    flex-direction: column;

    .modal-content,
    .modal-dialog {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      // .modal-header {
      //   .close {
      //     position: relative !important;
      //     right: 0 !important;
      //     padding-right: 0 !important;
      //     padding-left: 0 !important;
      //     padding-top: 6px !important;
      //   }
      // }
    }
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}

.modal-desktop {
  .modal-content {
    height: 90%;
  }

  .modal-dialog {
    height: 100%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-image: none;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: $white;
  opacity: 0.5;
}

#swiperCluster {
  .card,
  .card:hover {
    box-shadow: none !important;
  }

  .swiper-pagination-bullet-active {
    background: #f60 !important;
    opacity: 1 !important;
  }
}

.star-rating {
  font-size: 1.5rem;
  color: #b0c4de;
}

.star-rating-filled {
  color: #f2b01e !important;
}

.responsive-height {
  height: auto !important;
}

.responsive-height .modal-dialog {
  width: 53% !important;
  height: 60% !important;
}

.cloud-upload-icon {
  border-left: 0.0625em solid $success-color !important;
  border-top: 0.0625em solid $success-color !important;
  border-bottom: 0.0625em solid $success-color !important;
}

.cloud-upload-icon i::after {
  border-color: $success-color !important;
}

.cloud-upload-icon::before {
  border-left: 0.0625em solid transparent;
  border-right: 0.0625em solid $success-color !important;
  border-top: 0.0625em solid $success-color !important;
  border-bottom: 0.0625em solid $success-color !important;
}

.cloud-upload-icon::after,
.file-browse-button {
  background-color: $success-color !important;
}

.modal-content .modal-header button {
  position: absolute !important;
  right: 20px;
  top: 20px !important;
  outline: 0;
}

// .noUi-connects {
//   height: 18px;
// }

// .noUi-touch-area {
//   height: 45px;
//   margin-top: -110%;
//   width: 45px;
//   margin-left: -110%;
// }

.noUi-horizontal .noUi-tooltip {
  bottom: -320%;
}

.noUi-origin {
  top: 7px;
  // width: 10%;
}

.noUi-horizontal,
.noUi-connects {
  height: 10px;
  cursor: pointer;

  .noUi-handle {
    width: 20px;
    height: 20px;
  }
}

// .noUi-active {
//   transform: unset;
// }

// .noUi-tooltip {
//   display: none;
// }

// .noUi-active .noUi-tooltip {
//   display: block;
// }

.custom-tooltip {
  .noUi-active .noUi-tooltip {
    display: none !important;
  }
}

// dynamic form css
.rounded-textarea {
  border: 1px solid #dee2e6 !important;
  border-radius: 30px !important;
  max-height: 200px !important;
}

.nowui-checkboxes {
  input[type="checkbox"] {
    position: relative !important;
  }
}

.opacity-1 {
  opacity: 1 !important;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem -2rem;
}

aw-wizard .wizard-steps {
  display: block !important;
}

.course-template {
  table {
    border: transparent;
  }

  td {
    padding-bottom: 15px;
  }

  td:first-child {
    vertical-align: top;
    color: #969696;
    width: 25%;
  }
}

.jobs-alert .bootstrap-switch {
  margin-bottom: 0;
}

.stripe-card .card {
  margin-bottom: 0 !important;
  padding-left: 10px;
}

.stripe-card .field {
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
  background-color: #fff;
  padding-left: 10px;
}

.wp-block-cover {
  height: auto !important;
}

.user-segment {
  aw-wizard-navigation-bar {
    display: none !important;
  }
}

.bootstrap-switch {
  &.bootstrap-switch-indeterminate {
    .bootstrap-switch-handle-on {
      color: $primary_color;
    }
  }

  &.bootstrap-switch-disabled,
  &.bootstrap-switch-indeterminate,
  &.bootstrap-switch-readonly {
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
      opacity: initial;
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  top: auto !important;
}

button.icon-button {
  border: none;
  background: none;
}

.has-danger {
  .border-0 {
    border: 1px solid #fa6161 !important;

    &:first-child {
      border-right: 0 !important;
    }

    &:last-child {
      border-left: 0 !important;
    }
  }

  .icon,
  input {
    color: #ff3636c4 !important;

    &::placeholder {
      color: #ff3636c4 !important;
    }
  }

  &::after {
    content: "";
  }
}

.invalid-feedback {
  display: block;
  font-size: max(70%, 12px);
  margin-bottom: 10px;
}

button:disabled:hover {
  opacity: 0.6;
}

.form-group dynamic-ng-bootstrap-input input[type="file"] {
  opacity: 1 !important;
  position: relative !important;
}

.wp-block-image {
  img {
    height: auto;
  }
}

// experimental
input {
  min-height: 40px;
}

aw-wizard-navigation-bar {
  ul.steps-indicator {
    li {
      &.editing,
      &.current {
        .step-indicator {
          background-color: $secondary-color !important;
        }
      }

      &:not(.editing, .current) {
        .step-indicator {
          background-color: $info-color !important;
        }
      }
    }
  }
}

.dropdown-item {
  &.active,
  &:active {
    color: $white !important;
  }
}

.standard-dropdown {
  max-height: 240px;

  .dropdown-item {
    &:hover {
      background: #005eb8 linear-gradient(180deg, #2676c3, #005eb8) repeat-x !important;
      color: white !important;
    }
  }
}

@for $i from 1 through 20 {
  .icon-#{$i * 4} {
    font-size: 4px * $i !important;
  }
}

/* override for coach promo model */
.bg-transparent {
  &.promo-coach {
    max-width: 1164px;

    .modal-content {
      max-width: 1164px;
    }
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.visually-hidden {
  display: none;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.bootstrap-select .dropdown-menu.inner li a:hover,
.bootstrap-select .dropdown-menu.inner li a:focus {
  background-color: #005eb8;
}

.suffix-icon {
  padding: 10px 18px 10px 0 !important;
}

.sticky-top,
.sticky-bottom {
  z-index: 2 !important;
}

.input-group {
  &.border-success {
    border: 1px solid $success-color;
    border-radius: 30px;
  }
}

.showing-stage {
  display: none !important;
}

.btn-link.btn-icon {
  text-decoration: none;
}

// :not(.btn-neutral) {
//   & :not(.btn-check) + .btn:hover + :not(.bg-transparent) ,
//   .btn:first-child:hover + :not(.bg-transparent) {
//     color: white !important;
//   }
// }

.btn-close {
  background: white !important;
  /* stylelint-disable */
  mask-image: escape-svg($btn-close-bg);
  /* stylelint-enable */
  mask-size: 50% auto;
  mask-position: center center;
  mask-repeat: no-repeat;

  &.btn-red {
    background-color: var(--bs-red) !important;
    opacity: 1;
  }
}

.shadow.slider {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 30%) !important;
}

.btn-success {
  &:hover,
  &:disabled {
    color: white !important;
  }
}
/* stylelint-disable */
.input-group {
  $validation-messages: "";
  @each $state in map-keys($form-validation-states) {
    $validation-messages: $validation-messages +
      ":not(." +
      unquote($state) +
      "-tooltip)" +
      ":not(." +
      unquote($state) +
      "-feedback)";
  }

  > :not(:first-child):not(.dropdown-menu)#{$validation-messages}.bg-light-transparent-4 {
    margin-left: 0px !important;
  }
}
/* stylelint-enable */

// No idea why its not working by default "now ui?"
.btn-secondary {
  background-color: $secondary-color;
  color: #fff;
}

// WEB-3907
ngb-typeahead-window {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

certification-form-student-details {
  .dropdown .dropdown-menu:not(.inner) {
    top: 150% !important;
  }
}

.feedback-instructor-wrapper {
  ngb-rating {
    color: $yellow !important;
  }
}
