/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use "sass:math";

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "nouislider/dist/nouislider.min.css";
@import "bootstrap/scss/bootstrap";
@import "swiper/scss";
@import "node_modules/swiper/modules/pagination/pagination";
@import "node_modules/ngx-bar-rating/themes/br-default-theme";
@import "theme/libs/now-ui-kit";
@import "theme/custom/iko_font";
@import "theme/custom/flags";
// Theme customization
@import "theme/theme";
