.card {
  border: 0;
  border-radius: $border-radius-small;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

  .card-header {
    background-color: $transparent-bg;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  .card-footer {
    padding: 0;
    margin-top: 15px;
    background-color: $transparent-bg;
    border: 0;
  }

  &[data-background-color="orange"] {
    background-color: $primary-color;
  }

  &[data-background-color="red"] {
    background-color: $danger-color;
  }

  &[data-background-color="yellow"] {
    background-color: $warning-color;
  }

  &[data-background-color="blue"] {
    background-color: $info-color;
  }

  &[data-background-color="green"] {
    background-color: darken($success-color, 5%);
  }
}
