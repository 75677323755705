$primary-color: #005eb8;
$secondary-color: #28a745;
$success-color: #f60;
$info-color: #969696;
$light-gray: #dee2e6;
$header-height: 60px;

$primary: $primary-color;
$secondary: $secondary-color;
$success: $success-color;
$info: $info-color;

:root {
  --webkit-text-track-display: block;
}
