.phone-container {
  img {
    width: 100%;
  }
}

.features-2,
.features-1,
.features-3 {
  text-align: center;
}

.features-4 {
  .card {
    margin-top: 70px;
  }
}

.features-2 {
  .info-title,
  .title {
    color: $white-color;
  }
}

.features-5 {
  .info {
    max-width: none;
    margin: 0 auto;
    padding: 10px 0 0px;
  }
  .phone-container {
    max-width: 200px;
    margin: 0 auto;
  }
}

.features-6 {
  .phone-container {
    max-width: 260px;
    margin: 60px auto 0;
  }
  .info {
    max-width: none;
    margin: 0 auto;
    padding: 10px 0 0px;
  }
  .info:first-child {
    padding-top: 130px;
  }
}

.features-7,
.features-2,
.features-8 {
  position: relative;

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .container,
  .container-fluid,
  .title,
  .description {
    position: relative;
    z-index: 2;
  }
}

.features-8,
.features-7 {
  .title {
    color: $white;
  }
}

.features-8 {
  .title + .description {
    margin-bottom: 70px;
  }
}

.features-7 {
  overflow: hidden;

  .info-title {
    color: $white;
  }
  .info-horizontal {
    padding: 15px 0 0;

    &:first-child {
      padding-top: 45px;
    }
  }

  .image-container {
    max-width: 1200px;
    position: relative;
    height: 550px;
    margin-top: 58px;

    img {
      max-width: 1200px;
      left: 100px;
      top: 0;
      height: 100%;
      position: absolute;
    }
  }
}

.tablet-container {
  margin-top: 40px;
}
