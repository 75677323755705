.wp-block-cover {
  min-height: 245px;

  &.has-background-dim.border-success {
    border: 3px solid;
    border-radius: 10px;
  }
}

.cover-height-normal {
  min-height: 430px;
}

span.btn.btn-sm.btn-success {
  padding: 0 5px;
  font-size: max(0.75rem, 12px);
}

a.wp-block-button__link.has-background.has-luminous-vivid-orange-background-color {
  border-radius: 10px;
  text-decoration: none;
}

.wp-block-spacer {
  height: 50px !important;
}

.rounded-1 img {
  border-radius: 8px;
}

.member-subheading {
  font-size: 15px;
}
@media screen and (min-width: 800px) {
  h4 {
    font-size: 1.125em;
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.45;
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.4;
  }

  h1 {
    font-size: 2em;
    line-height: 1.35;
  }
}

@media (max-width: 800px) {
  .modal-content .modal-header button {
    position: fixed;
    z-index: 1;
  }

  h4 {
    font-size: 1em;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.25em;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.75em;
    line-height: 1.2;
  }

  h1 {
    font-size: 2.25em;
    line-height: 1.2;
  }

  .wp-block-spacer {
    height: 30px !important;
  }

  .footer-margin {
    margin-bottom: 5rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1281px) {
  .modal-dialog {
    max-width: 90%;
    margin: 1.75rem auto;
  }

  .modal-image-picker {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
}

@media (min-width: 1920px) {
  .modal-xl {
    max-width: 85%;
    width: 85%;
  }

  .container-xl,
  .container {
    max-width: 100%;
  }
}

// custom css for public pages
@media (max-width: 800px) {
  .content {
    font-size: 80% !important;
  }

  .wp-block-cover {
    min-height: 125px;
  }

  .wp-block-cover__inner-container,
  .wp-block-column {
    margin: 0 15px;

    h4 {
      font-size: 1.125em;
      line-height: 1.2;
    }

    h3 {
      font-size: 1.375em;
      line-height: 1.2;
    }

    h2 {
      font-size: 1.625em;
      line-height: 1.2;
    }

    h1 {
      font-size: 2em;
      line-height: 1.2;
    }
  }

  a.subheading {
    font-size: 12px;
  }

  .wp-block-column.m-small {
    margin: 0;
  }

  h6.home-mob-heading {
    margin-top: -50px;
  }
}

.faq-margin {
  margin-top: -60px;
}

@include media-breakpoint-up(lg) {
  .wave-img {
    top: -6vw !important;
  }
}

.hide-desktop {
  @media (min-width: 1024.02px) {
    display: none !important;
  }
}

$keyBoardHeightBreakPoint: 500px;

.show-keyboard-open {
  display: none !important;
}

@media (max-height: 500px) {
  .show-keyboard-open {
    display: flex !important;
  }

  .hide-keyboard-open {
    display: none !important;
  }
}

.safe-area-bottom {
  padding-bottom: env(safe-area-inset-bottom) !important;
}
